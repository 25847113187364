<template>
  <div class="content-info-cupo">
    <div class="d-flex align-items-center">
      <span
        v-if="step > 0"
        class="d-flex align-items-center"
        style="cursor: pointer"
        @click="
          step == 1
            ? paymentVal
              ? (paymentVal = false)
              : (step = 0)
            : (step = 0)
        "
        ><img src="@/assets/icons/arrow_back.svg" style="max-width: 20px" />
        Volver</span
      >
    </div>
    <h2 class="text-center mb-4">Selecciona una de las siguientes opciones</h2>
    <div v-if="step == 0" class="step-1-infocupo">
      <p class="mb-4 mx-auto text-center" style="max-width: 700px">
        Por favor ingresa la <b>cédula</b> del titular.
      </p>
      <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
        <div class="input-simple-clover">
          <label
            :class="validateDoc ? 'invalid' : ''"
            class="label-input"
            :style="nDoc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
            >Ingresa número de cédula</label
          >
          <input
            v-model="nDoc"
            type="number"
            :class="validateDoc ? 'invalid-error' : ''"
            placeholder="Ingresa número de cédula"
            @blur="validateBlur('validateDoc')"
            @focus="validateDoc = false"
          />
          <span v-if="validateDoc" class="inavalida-input">{{
            nDoc ? 'Documento invalido' : 'Campo requerido'
          }}</span>
        </div>
      </div>
      <div class="col-md-8 col-lg-5 col-xl-4 px-md-3 mx-auto d-flex flex-wrap">
        <div class="col-12 mb-4 col-md-6 pe-md-2">
          <button class="w-100 bnt-outline" @click="senDocEstadoCuenta()">
            Ver estado cuenta
          </button>
        </div>
        <div class="col-12 mb-4 col-md-6 ps-md-2">
          <button class="w-100 bnt-primary" @click="senDoc()">
            Ir a pagar
          </button>
        </div>
      </div>
      <div v-if="errorInfoCupo" class="mb-4">
        <span class="text-error">{{ msgErrorInfocupo }}</span>
      </div>
    </div>
    <div v-else-if="step == 1" class="step-2-infocupo">
      <div
        v-if="
          infoValidateDoc.puede_abonar == 1 &&
            infoValidateDoc.facturas.length > 0
        "
        class="w-100"
      >
        <div class="col-md-8 col-lg-6 col-xl-5 mb-4 px-md-3 mx-auto">
          <p>
            Hola, <b>{{ infoValidateDoc.data.cliente_nombre }}</b
            >, te ofrecemos dos opciones para tu mayor comodidad, realiza el
            <b>pago anticipado</b> de tu cupo y ahórrate hasta
            <b>${{ new Intl.NumberFormat('de-DE').format(ahorras) }}</b>
          </p>
          <div class="container-acordeon">
            <b-card-header
              class="header-tag-anticipado header-card"
              header-tag="header"
              role="tab"
            >
              <div v-b-toggle.accordion-1 block>
                <div class="w-100 d-flex justify-content-between">
                  <span class="title-pago-anticipado"
                    >Realiza pago anticipado</span
                  >
                  <img
                    src="@/assets/icons/arrow-simple.svg"
                    class="img-rotate"
                  />
                </div>
              </div>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              accordion="payment-methods"
              role="tabpanel"
              class="body-card border-only-LRB"
            >
              <b-card-body>
                <p>Selecciona la factura que deseas pagar</p>
                <div class="table-responsive container-table-anticipado">
                  <table class="table table-pago-anticipado">
                    <tbody>
                      <tr class="table-title-pago-anticipado">
                        <th></th>
                        <th><b>No. factura</b></th>
                        <th><b>Total</b></th>
                        <th><b>Valor a pagar</b></th>
                        <th><b>Tu ahorro</b></th>
                      </tr>
                      <tr
                        v-for="(factura, index) in infoValidateDoc.facturas"
                        :key="'data' + index"
                        class="tr-content-pago-anticipado"
                        :class="
                          selectPago == factura.factura_id
                            ? 'tr-content-pago-anticipado-select'
                            : ''
                        "
                        @click="selectPago = factura.factura_id"
                      >
                        <td>
                          <div
                            class="check-price-pay-cupo p-0 mb-0"
                            style="background-color: transparent"
                          >
                            <input
                              :id="'input-checkbox-' + factura.factura_id"
                              v-model="selectPago"
                              type="radio"
                              class="input-checkbox"
                              :value="factura.factura_id"
                              checkmark="checkmark"
                            />
                          </div>
                        </td>
                        <td>
                          {{ factura.factura_tipo }}-{{
                            factura.factura_numero
                          }}
                        </td>
                        <td>
                          ${{
                            new Intl.NumberFormat('de-DE').format(
                              factura.vlr_total,
                            )
                          }}
                        </td>
                        <td>
                          ${{
                            new Intl.NumberFormat('de-DE').format(
                              factura.vlr_a_pagar,
                            )
                          }}
                        </td>
                        <td>
                          ${{
                            new Intl.NumberFormat('de-DE').format(
                              factura.vlr_descuento,
                            )
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button
                  class="col-12 col-md-5 bnt-primary mt-3"
                  :class="selectPago == null ? 'disabled' : ''"
                  @click="payAnticipated()"
                >
                  Realizar pago
                </button>
                <div class="col-12 mt-4">
                  <span class="msgError">{{ errValor2 }}</span>
                </div>
              </b-card-body>
            </b-collapse>
            <div class="mb-4" />
            <b-card-header
              class="header-tag-anticipado header-card"
              header-tag="header"
              role="tab"
            >
              <div v-b-toggle.accordion-2 block>
                <div class="w-100 d-flex justify-content-between">
                  <span class="title-pago-anticipado"
                    >Pagar un valor personalizado</span
                  >
                  <img
                    src="@/assets/icons/arrow-simple.svg"
                    class="img-rotate"
                  />
                </div>
              </div>
            </b-card-header>
            <b-collapse
              id="accordion-2"
              accordion="payment-methods"
              role="tabpanel"
              class="body-card border-only-LRB"
            >
              <b-card-body>
                <p class="text-center mx-auto mb-3">
                  <strong>{{
                    infoValidateDoc.data.cliente_nombre
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}</strong
                  >, ingresa el valor del pago a realizar
                </p>
                <div class="col-12 col-md-6 mx-auto py-3">
                  <div class="position-relative ms-auto otro-valor-input">
                    <input
                      v-model="valor"
                      type="number"
                      class="payment-input otro-volar w-100"
                      placeholder="Valor cuota"
                      name="otro_valor"
                    />
                    <span v-if="valor" class="newlatter-error-input"
                      >Valor cuota</span
                    >
                  </div>
                </div>
                <button
                  class="col-12 col-md-6 mx-auto mb-4 CRÉDITO bnt-primary"
                  @click="payUWebCheckOutPayU()"
                >
                  Realizar pago
                </button>
                <div class="col-12 mt-4">
                  <span class="msgError">{{ errValor }}</span>
                </div>
              </b-card-body>
            </b-collapse>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!paymentVal">
          <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
            <p class="fw-bold mb-3 text-center">Valor a pagar</p>
            <p class="mb-2">
              A continuación realizarás un pago a nombre de
              <b>{{ infoValidateDoc.data.cliente_nombre }}</b>
            </p>
            <p v-if="infoValidateDoc.data.total_deuda > 0" class="mb-3">
              Ingresa el valor de la cuota a cancelar.
            </p>
          </div>
          <div v-if="infoValidateDoc.data.total_deuda > 0">
            <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
              <div class="input-simple-clover">
                <label
                  :class="validateValor ? 'invalid' : ''"
                  class="label-input"
                  :style="valor ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
                  >Valor a pagar</label
                >
                <input
                  v-model="valor"
                  :class="validateValor ? 'invalid-error' : ''"
                  placeholder="Valor a pagar"
                  type="number"
                  @blur="validateBlur('validateValor')"
                  @focus="validateValor = false"
                />
                <span v-if="validateValor" class="inavalida-input">{{
                  valor ? 'monto minimo $10.000' : 'Campo requerido'
                }}</span>
              </div>
            </div>
            <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
              <div
                v-if="valor > infoValidateDoc.data.total_deuda * 1.05"
                class="mb-4 p-3"
                style="background-color: #ebeeff; border-radius: 5px"
              >
                <p class="mb-0">El monto a pagar supera la deuda total</p>
              </div>
              <button
                :disabled="
                  valor == null ||
                    valor == 0 ||
                    valor > infoValidateDoc.data.total_deuda * 1.05
                "
                :style="
                  valor == null ||
                  valor == 0 ||
                  valor > infoValidateDoc.data.total_deuda * 1.05
                    ? 'opacity: .7'
                    : ''
                "
                class="bnt-primary w-100"
                @click="paymentVal = true"
              >
                {{
                  valor
                    ? `Pagar $${new Intl.NumberFormat('de-DE').format(valor)}`
                    : 'Pagar'
                }}
              </button>
            </div>
          </div>
          <div v-else class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
            <p
              class="mb-0 summary-infocupo-pago-cuota"
              style="background-color: #d1efc8"
            >
              ¡Felicitaciones, estás al día en tus pagos!
            </p>
          </div>
        </div>
        <div v-else>
          <p class="fw-bold">Seleccionar método de pago</p>
          <div class="summary-infocupo-pago-cuota card-botones-payment">
            <p class="w-100 fw-bold">Resumen de pago</p>
            <ul class="ps-0 mb-0 w-100">
              <li class="d-flex justify-content-between mb-2">
                <td>{{ infoValidateDoc.data.cliente_nombre }}</td>
                <td>
                  Total a pagar:
                  <b>${{ new Intl.NumberFormat('de-DE').format(valor) }}</b>
                </td>
              </li>
              <li class="d-flex justify-content-between mb-2">
                <td>{{ nDoc }}</td>
                <td>
                  <span
                    style="text-decoration: underline; cursor: pointer"
                    @click="paymentVal = false"
                    >Cambiar valor</span
                  >
                </td>
              </li>
            </ul>
          </div>
          <div v-if="errValor" class="mb-4">
            <span class="text-error">{{ errValor }}</span>
          </div>
          <div
            class="card-botones-payment"
            style="cursor: pointer"
            @click="payUWebCheckOutBancolombia()"
          >
            <p class="mb-0">
              <b>Bancolombia</b><br />Transfiere desde tu cuenta Bancolombia
            </p>
            <div
              style="max-width: 150px; min-width: 145px; cursor: pointer"
              class="ms-2 text-center"
            >
              <span style="font-size: 14px">clic para pagar</span>
              <img
                class="w-100"
                src="@/assets/icons/logo_boton_bancolombia.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="card-botones-payment"
            style="cursor: pointer"
            @click="payUWebCheckOutPayU()"
          >
            <p class="mb-0">
              <b>PayU</b><br />Paga a través de cualquier medio:
              <b>PSE, Tarjeta de crédito, Tarjeta de débito o Efecty</b>
            </p>
            <div
              style="max-width: 150px; min-width: 145px; cursor: pointer"
              class="ms-2 text-center"
            >
              <span style="font-size: 14px">clic para pagar</span>
              <img
                class="w-100"
                src="@/assets/icons/logo_boton_payU.png"
                alt=""
              />
            </div>
          </div>
          <div
            class="card-botones-payment justify-content-center"
            style="border: none; background-color: #fff2dc"
          >
            <p class="mb-0 text-center">
              Recuerda que el valor mínimo de pago en EFECTY es de $20.000
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="step == 2" class="step-3-infocupo">
      <div v-if="!sendedSucces">
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 mx-auto">
          <p class="mb-4 mx-auto text-start" style="max-width: 700px">
            {{ infoValidateDoc.cliente_nombre }} para proteger tus datos
            CLOVERBOLSOS.COM quiere verificar que eres tú quien está intentando
            consultar el estado de cuenta.
          </p>
          <p class="text-start mb-0">
            Confirma el número de teléfono que indicaste en la solicitud de cupo
            personal: {{ infoValidateDoc.celular }}
          </p>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 mx-auto">
          <div class="input-simple-clover">
            <label
              :class="invalidTel ? 'invalid' : ''"
              class="label-input"
              :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Número de celular</label
            >
            <input
              v-model="tel"
              type="number"
              placeholder="Número de celular"
              :class="invalidTel ? 'invalid-error' : ''"
              @blur="validateBlur('validateTel')"
              @focus="invalidTel = false"
            />
            <span v-if="invalidTel" class="inavalida-input">{{
              tel ? 'Documento invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mx-auto">
          <div v-if="errorInfoCupo" class="text-center mb-4">
            <span class="text-error">{{ msgErrorInfocupo }}</span>
          </div>
          <p
            class="container-send-data-infocupo mb-4 w-100 p-3 d-block"
            style="
              cursor: default;
              border: none;
              background-color: rgb(255, 231, 203);
            "
          >
            ¡Importante! CLOVERBOLSOS.COM en ningún caso solicitara compartir
            información de tu estado de cuenta, ante cualquier sospecha de
            fraude comunícate con nuestra línea de atención Whatsapp
            <a
              target="_blank"
              style="color: black"
              href="https://api.whatsapp.com/send?phone=573208743430&text=asesor"
              >320 874 3430</a
            >
          </p>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 mx-auto">
          <button class="bnt-primary w-100" @click="sendValidatePhone()">
            Enviar
          </button>
        </div>
      </div>
      <div v-else>
        <div class="summary-infocupo-pago-cuota card-botones-payment">
          <p class="mb-0">
            {{ infoValidateDoc.cliente_nombre }} hemos enviado un mensaje de
            texto a tu celular con la información de tu estado de cuenta.
          </p>
        </div>
        <h5 class="text-center px-4 mb-5">
          NO COMPARTAS ESTA INFORMACIÓN CON NADIE
        </h5>
      </div>
    </div>
    <div class="d-flex flex-wrap justify-content-center mb-4">
      <p class="text-center w-100">Recibimos los siguientes medios de pago:</p>
      <img
        src="@/assets/icons/medios_pago.svg"
        class="w-100"
        style="max-width: 472px"
      />
    </div>
    <div class="footer-infocupo">
      <p class="text-center">
        ¿Necesitas ayuda? Comunícate con nuestra
        <b
          >línea de atención
          <a style="color: #8b9ca2" href="tel:3003870181">300 387 0181</a></b
        >
      </p>
    </div>
    <div id="payUWebChecOut" v-html="htmlPayU" />
  </div>
</template>
<script>
import { BCardBody, BCardHeader, BCollapse } from 'bootstrap-vue'
import { mapState, mapActions } from 'vuex'
import $ from 'jquery'
export default {
  components: { BCardBody, BCardHeader, BCollapse },
  data() {
    return {
      nDoc: '',
      validateDoc: false,
      valor: null,
      validateValor: false,
      code: '',
      validateCode: false,
      valToPay: 0,
      otherValToPay: '',
      validateOtherValToPay: false,
      step: 0,
      paymentVal: false,
      errValor: '',
      tel: '',
      invalidTel: false,
      sendedSucces: false,
      htmlPayU: '',

      selectPago: null,
      errValor2: '',
      ahorras: 0,
    }
  },
  computed: {
    ...mapState('infocupo', [
      'errorInfoCupo',
      'msgErrorInfocupo',
      'infoValidateDoc',
    ]),
    validateTel() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  watch: {
    step() {
      window.scrollTo(0, 0)
      if (this.step == 0) {
        this.resetData()
      }
    },
    infoValidateDoc() {
      if (this.infoValidateDoc) {
        this.ahorras = 0
        this.infoValidateDoc.facturas.forEach(element => {
          this.ahorras += parseInt(element.vlr_descuento)
        })
      }
    },
  },
  async mounted() {
    if (this.$route.params.document) {
      await this.validateDocClient({
        documento: this.$route.params.document,
      }).then(() => {
        if (!this.errorInfoCupo) {
          this.nDoc = this.$route.params.document
          this.step = 1
          delete this.$route.params.document
        }
      })
    }
  },
  methods: {
    ...mapActions('infocupo', [
      'validateDocClient',
      'validateDocClientEstadoCuenta',
      'validateTelClientEstadoCuenta',
    ]),
    async payAnticipated() {
      if (this.selectPago > null) {
        this.errValor2 = ''
        this.$store.state.loaderDerek = true
        let data = new FormData()
        data.append('documento', this.infoValidateDoc.data.documento)
        data.append('factura_id', this.selectPago)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/payu/prontoPago/webCheckout',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderDerek = false
            if (err.response.data.message) {
              this.errValor2 = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor2 = message
            }
          })
      }
    },
    resetData() {
      this.nDoc = ''
      this.validateDoc = false
      this.valor = null
      this.validateValor = false
      this.code = ''
      this.validateCode = false
      this.valToPay = 0
      this.otherValToPay = null
      this.validateOtherValToPay = false
      this.step = 0
      this.paymentVal = null
      this.errValor = ''
      this.tel = ''
      this.invalidTel = false
      this.sendedSucces = false
      this.$store.state.infocupo.infoValidateDoc = null
      this.$store.state.infocupo.errorInfoCupo = false
      this.$store.state.infocupo.msgErrorInfocupo = ''
    },
    validateBlur(focus) {
      if (focus == 'validateTel') {
        this.invalidTel = !this.validateTel
      }
    },
    async senDocEstadoCuenta() {
      if (this.nDoc.length > 4 && this.nDoc.length <= 10) {
        this.validateDoc = false
        await this.validateDocClientEstadoCuenta({ documento: this.nDoc }).then(
          () => {
            if (!this.errorInfoCupo) {
              this.step = 2
            }
          },
        )
      } else {
        this.validateDoc = true
      }
    },
    async senDoc() {
      if (this.nDoc.length > 4 && this.nDoc.length <= 10) {
        this.validateDoc = false
        await this.validateDocClient({ documento: this.nDoc }).then(() => {
          if (!this.errorInfoCupo) {
            this.step = 1
          }
        })
      } else {
        this.validateDoc = true
      }
    },
    validateToken() {
      if (this.code.length == 6) {
        this.validateCode = false
        this.step += 1
      } else {
        this.validateCode = true
      }
    },
    async sendValidatePhone() {
      if (this.validateTel) {
        await this.validateTelClientEstadoCuenta({
          celular: this.tel,
          documento: this.nDoc,
        }).then(() => {
          if (!this.errorInfoCupo) {
            this.sendedSucces = true
          }
        })
      } else {
        this.invalidTel = true
      }
    },
    async payUWebCheckOutPayU() {
      if (this.valor > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderClover = true
        let data = new FormData()
        data.append('documento', this.nDoc)
        data.append('valor_abono', this.valor)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/payu/cuotaCupo/webCheckout',
          data: data,
        })
          .then(response => {
            this.htmlPayU = response.data
            setTimeout(function() {
              $('#form__PagarCuotaCupoPayu').submit()
            }, 1000)
          })
          .catch(err => {
            this.$store.state.loaderClover = false
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
    async payUWebCheckOutBancolombia() {
      if (this.valor > 0) {
        this.errValor = ''
        this.validatePrice = false
        this.$store.state.loaderClover = true
        let data = new FormData()
        data.append('documento', this.nDoc)
        data.append('valor_abono', this.valor)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/apiBancolombia/cuotaCupo/webCheckout',
          data: data,
        })
          .then(response => {
            location.href = response.data.urlPayment
          })
          .catch(err => {
            this.$store.state.loaderClover = false
            if (err.response.data.message) {
              this.errValor = err.response.data.message
            } else {
              let message = ''
              console.log(err.response.data)
              for (const property in err.response.data.errors) {
                message = message + err.response.data.errors[property] + ' '
              }
              this.errValor = message
            }
          })
      } else {
        this.validatePrice = true
      }
    },
  },
}
</script>
<style lang="scss" scope>
.header-card div {
  background-color: #ebebeb !important;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  .img-rotate {
    transition: all 0.5s;
  }
  &.collapsed .img-rotate {
    transform: rotate(90deg);
  }
  &.not-collapsed .img-rotate {
    transform: rotate(-90deg);
  }
}
.body-card {
  padding: 0.8rem;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}
</style>
